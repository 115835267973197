import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/gallery/3.jpg";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Corbett Aroma Park</h1>
        </h1>
        <p>
        Immerse yourself in luxury with our spacious, well-appointed rooms, modern amenities, and breathtaking views of nature. Located near Corbett National Park, enjoy thrilling safaris to witness majestic tigers and diverse wildlife in their natural habitat. Create unforgettable memories amidst the wilderness and indulge in adventures that last a lifetime.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="The Pearl 21 Villa Image"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
